import React from 'react';
import { useUser } from '../context/userContext';

const GamePlay = () => {
  const { fullName, balance} = useUser();

  const getInitials = (username) => {
    const nameParts = username.split(' ');
    return nameParts[0].charAt(0).toUpperCase() + nameParts[0].charAt(1).toUpperCase();
  };


  const formatNumber = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3).replace(".", ".") + " M";
    }
  };

  const shortenName = (name) => {
    // Check if the name is longer than 16 characters
    if (name.length > 16) {
      return name.substring(0, 16) + '...'; // Return the first 16 characters followed by '...'
    }
    return name; // Return the original name if it's less than or equal to 16 characters
  };

  return (
    <>
      <div className='w-full flex flex-col pt-0 justify-center mb-3 items-center '>
        <div className='w-full relative bg-[#090600] rounded-[8px] leadershadow flex flex-col space-y-2'>
          {/* Display the active user's total game score */}
          <div className='bg-[#202124] py-2 px-3 flex flex-col font-medium w-full rounded-[8px]'>
            <h2 className="text-[13px] text-secondary font-semibold">Total JAGS</h2>
            <div className="w-full rounded-[16px] py-2 flex items-center justify-between space-x-3">
              <div className='w-fit'>
                <div className="flex items-center justify-center h-[38px] w-[38px] rounded-full p-1 bg-blue-500">

                  <span className='font-semibold text-[14px]'>{getInitials(fullName)}</span>
                </div>
              </div>
              <div className="flex h-full flex-1 flex-col justify-center relative">
                <div className='flex w-full flex-col justify-between h-full space-y-[2px]'>
                  <h1 className="text-[14px] text-nowrap line-clamp-1 font-medium">
                    {shortenName(fullName)}
                  </h1>
                </div>
              </div>
              <div className='w-fit flex items-center gap-1 justify-end flex-wrap text-[14px] relative px-1'>
              <img src='/stars2.svg' alt='dvf' className='w-[14px]' />
                <span className={`font-semibold ease-in duration-200`}>
                  {formatNumber(balance)} {/* Replaced rank with balance */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GamePlay;
