import React, { useEffect, useState, useRef } from 'react';
import Animate from '../Components/Animate.js';
import Spinner from '../Components/Spinner';
import { useUser } from '../context/userContext';
import NewUser from '../Components/NewUser';
import CommunitySlider from '../Components/Slides';
import TasksMenu from '../Components/TasksMenu';
import YourRewards from '../Components/YourRewards';
import { NavLink } from 'react-router-dom';

const AlphaDogs = () => {
  const { balance, loadingTwo, setLoadingTwo, checker, setChecker, loading } = useUser();
  const [checking, setChecking] = useState(false);
  const [welcome, setWelcome] = useState(true);
  const [isStoryOpen, setIsStoryOpen] = useState(false); // For managing the story modal
  const [currentStory, setCurrentStory] = useState(0); // Current story index
  const [isPaused, setIsPaused] = useState(false); // For pausing the timer
  const timerRef = useRef(null); // Reference to the timer
  const progressRefs = useRef([]); // References to progress bars

  // Sample stories data
  const stories = [
    { id: 1, type: 'image', src: '/home.jpg', caption: 'We are live! 🎉' },
    { id: 2, type: 'text', content: 'Our Play-to-Earn platform has officially launched! Play games, complete tasks, and earn tokens! 🚀 Invite your friends and stay tuned for updates and exclusive airdrops! 💰' },
    { id: 3, type: 'image', src: '/congrats2.gif', caption: 'Thanks for being here' },
    
    // Add more stories as needed
  ];

  useEffect(() => {
    // Simulate loading
    setTimeout(() => {
      setLoadingTwo(false);
    }, 2000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isStoryOpen && !isPaused) {
      startTimer();
    } else {
      clearTimer();
    }

    return () => clearTimer();
    // eslint-disable-next-line
  }, [isStoryOpen, currentStory, isPaused]);

  useEffect(() => {
    // Reset progress bars when story changes
    resetProgressBars();
    // eslint-disable-next-line
  }, [currentStory]);

  const startTimer = () => {
    clearTimer();
    timerRef.current = setTimeout(() => {
      handleNextStory();
    }, 5000); // Change story every 5 seconds
  
    // Start progress bar animation for the current story
    if (progressRefs.current[currentStory]) {
      progressRefs.current[currentStory].style.transition = 'width 5s linear';
      progressRefs.current[currentStory].style.width = '100%';
    }
  };
  

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleNextStory = () => {
    if (currentStory < stories.length - 1) {
      setCurrentStory((prevStory) => {
        // Clear the current story's progress bar and move to the next one
        if (progressRefs.current[prevStory]) {
          progressRefs.current[prevStory].style.width = '0%';
        }
        return prevStory + 1;
      });
    } else {
      setIsStoryOpen(false); // Close modal after the last story
    }
  };
  

  const handlePrevStory = () => {
    if (currentStory > 0) {
      setCurrentStory(currentStory - 1);
    }
  };

  const handleMouseEnter = () => {
    setIsPaused(true);
    clearTimer();
    pauseProgressBar();
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
    resumeProgressBar();
  };

  const formatNumber = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3).replace(".", ".") + " M";
    }
  };

  const checkers = () => {
    setWelcome(false);
    setChecking(true);
  };

  const toggleStory = () => {
    setIsStoryOpen(!isStoryOpen); // Toggle the story modal
    setCurrentStory(0); // Reset to first story when opening
    resetAllProgressBars();
    // Start the first progress bar
    if (!isStoryOpen) {
      setTimeout(() => {
        startTimer();
      }, 100); // Slight delay to ensure modal is rendered
    }
  };

  // Progress Bar Functions
  const resetProgressBars = () => {
    if (progressRefs.current[currentStory]) {
      progressRefs.current[currentStory].style.transition = 'none';
      progressRefs.current[currentStory].style.width = '0%';
      // Trigger reflow to restart the animation
      void progressRefs.current[currentStory].offsetWidth;
      progressRefs.current[currentStory].style.transition = 'width 5s linear';
      progressRefs.current[currentStory].style.width = '100%';
    }
  };

  const resetAllProgressBars = () => {
    progressRefs.current.forEach((bar, index) => {
      if (bar) {
        bar.style.transition = 'none';
        bar.style.width = '0%';
      }
    });
  };

  const pauseProgressBar = () => {
    if (progressRefs.current[currentStory]) {
      const computedStyle = window.getComputedStyle(progressRefs.current[currentStory]);
      const width = computedStyle.getPropertyValue('width');
      const parentWidth = progressRefs.current[currentStory].parentElement.offsetWidth;
      const percentage = (parseFloat(width) / parentWidth) * 100;
      progressRefs.current[currentStory].style.transition = 'none';
      progressRefs.current[currentStory].style.width = `${percentage}%`;
    }
  };

  const resumeProgressBar = () => {
    if (progressRefs.current[currentStory]) {
      const computedStyle = window.getComputedStyle(progressRefs.current[currentStory]);
      const width = parseFloat(computedStyle.getPropertyValue('width'));
      const parentWidth = progressRefs.current[currentStory].parentElement.offsetWidth;
      const remainingPercentage = 100 - (width / parentWidth) * 100;
      const remainingTime = (remainingPercentage / 100) * 5000;

      progressRefs.current[currentStory].style.transition = `width ${remainingTime}ms linear`;
      progressRefs.current[currentStory].style.width = '100%';

      timerRef.current = setTimeout(() => {
        handleNextStory();
      }, remainingTime);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Animate>
          <div className="w-full flex justify-center flex-col px-4">
            <div id="refer" className="w-full h-screen pt-5 scroller space-y-3 overflow-y-auto pb-[180px]">
              {loadingTwo && (
                <div className="flex justify-center items-center h-full">
                  <div className="w-[50px] h-[50px] border-[6px] border-[#6c6c6c] mt-[-20%] border-dashed rounded-full animate-spin marco"></div>
                </div>
              )}

              <div className={`w-full overflow-hidden space-y-3 ${loadingTwo ? 'hidden' : 'block'}`}>
                {/* Status with Story Click */}
                <div 
  className='w-full h-[60px] overflow-hidden rounded-[12px] bg-btn flex items-center justify-center text-center font-semibold relative cursor-pointer'
  onClick={toggleStory}
>
  <img src='/scorebg.svg' alt='ifd' className='object-cover object-center w-[400px] h-[220px] absolute left-[6px] mt-[-10px] right-0'/>
  <div className='relative flex items-center justify-center text-white'>
  <div className="w-full h-[60px] overflow-hidden flex items-center justify-center text-center font-semibold relative cursor-pointer">
    <img src='/scoreIcon2.svg' alt='score' className='icon-image mx-2' />
    <span className="text-white">Status Update</span>
  </div>
</div>

</div>


                {/* Story Modal */}
                {isStoryOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div 
                      className="relative w-[90%] h-[90%] bg-black rounded-lg p-0 overflow-hidden flex flex-col"
                      onMouseDown={handleMouseEnter}
                      onMouseUp={handleMouseLeave}
                      onTouchStart={handleMouseEnter}
                      onTouchEnd={handleMouseLeave}
                    >
                      {/* Progress Indicators */}
                      <div className="flex space-x-2 p-4 absolute top-4 left-4 right-4 z-10">
                        {stories.map((story, index) => (
                          <div key={story.id} className="flex-1 h-1 bg-gray-500 rounded">
                            <div
                              ref={el => progressRefs.current[index] = el}
                              className={`h-full bg-gradient-to-r from-blue-500 via-blue-500 to-blue-500 rounded`}
                              style={{
                                width: index < currentStory ? '100%' : index === currentStory ? '0%' : '0%',
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>

                      {/* Previous Story Button */}
                      <button 
                        onClick={() => {
                          handlePrevStory();
                          resetProgressBars();
                          startTimer();
                        }}
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-3xl font-bold z-20"
                        aria-label="Previous Story"
                      >
                        &#8249;
                      </button>

                      {/* Next Story Button */}
                      <button 
                        onClick={() => {
                          handleNextStory();
                          resetProgressBars();
                          startTimer();
                        }}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-3xl font-bold z-20"
                        aria-label="Next Story"
                      >
                        &#8250;
                      </button>

                      {/* Story Content */}
                      <div 
                        className="flex-1 flex items-center justify-center px-4"
                        onMouseEnter={handleMouseEnter} 
                        onMouseLeave={handleMouseLeave}
                        onTouchStart={handleMouseEnter}
                        onTouchEnd={handleMouseLeave}
                      >
                        {stories[currentStory].type === 'image' && (
                          <img 
                            src={stories[currentStory].src} 
                            alt={`Story ${currentStory + 1}`} 
                            className="max-w-full max-h-full object-contain rounded-lg"
                          />
                        )}
                        {stories[currentStory].type === 'text' && (
                          <div className="text-white text-2xl font-semibold text-center">
                            {stories[currentStory].content}
                          </div>
                        )}
                      </div>

                      {/* Caption (for image stories) */}
                      {stories[currentStory].type === 'image' && stories[currentStory].caption && (
                        <div className="absolute bottom-10 w-full flex justify-center">
                          <p className="text-white text-lg bg-black bg-opacity-50 px-4 py-2 rounded">
                            {stories[currentStory].caption}
                          </p>
                        </div>
                      )}

                      {/* Close Button */}
                      <button 
                        onClick={toggleStory} 
                        className="absolute top-4 right-4 text-white text-2xl font-bold z-20"
                        aria-label="Close Story"
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                )}

                {/* Other Content */}
                <div className='w-full flex justify-center bg-[#181818] rounded-[12px] relative overflow-hidden'>
                  <img src='/blurbg.svg' alt='ifd' className='object-cover absolute left-0 w-full right-0 h-[200px] object-center rounded-[12px]'/>
                  <div className='w-full relative flex flex-col px-6 pt-6 items-center'>
                    <img src='stars.svg' alt='stars' className='w-[60px]'/>
                    <h4 className='font-semibold text-[32px] pt-2'>
                      {formatNumber(balance)}
                    </h4>
                    <p className='pb-6'>$JAGS</p>
                    <div className='w-full pb-4'>
                      <NavLink to='/airdrop' className='bg-btn h-[50px] px-4 rounded-[10px] flex items-center justify-center space-x-1 font-semibold text-[15px]'>
                        <img src='/tons.svg' alt='dfd' className='w-[26px] mt-[2px]'/>
                        <span>Withdraw to wallet</span>
                      </NavLink>
                    </div>
                  </div>
                </div>

                <CommunitySlider/>

                <div className='w-full flex flex-col'>
                  <TasksMenu/>
                  <YourRewards/>
                </div>
              </div>

              {checker && (
                <div 
                  className={`visible fixed bottom-0 left-0 z-40 right-0 top-[-12px] taskbg flex justify-center items-center`}
                >
                  <div className="w-full flex h-full taskbg mt-[2px] justify-center relative flex-col items-center px-4">
                    {welcome && (
                      <div className='w-full h-full relative pb-24 flex items-center justify-center flex-col space-y-3'>
                        <div className='absolute w-[180px] h-[180px]'>
                          <img src='/stars.svg' alt='gods' className='w-full'/>
                        </div>

                        <div className='absolute bottom-10 flex flex-col w-full justify-center items-center space-y-5'>
                          <p className='w-full text-center max-w-[20em]'>
                            Welcome to $JAGS!<br/> 🎮 Play, Earn, and Unlock Rewards! 💰
Start your journey now!
                          </p>
                          {balance > 0 ? (
                            <button onClick={() => setChecker(false)} className='w-full py-3 rounded-[8px] font-semibold px-3 flex items-center justify-center text-center bg-btn'>
                              Let's go!
                            </button>
                          ) : (
                            <button onClick={checkers} className='w-full py-3 rounded-[8px] font-semibold px-3 flex items-center justify-center text-center bg-btn'>
                              Let's go!
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    {checking && (
                      <NewUser setChecker={setChecker}/>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Animate>
      )}
    </>
  );
};

export default AlphaDogs;
