//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDafqITTuvGXD44yvrpRWRiFZaWprEHLOQ",
  authDomain: "jags-6fe4d.firebaseapp.com",
  projectId: "jags-6fe4d",
  storageBucket: "jags-6fe4d.appspot.com",
  messagingSenderId: "419369971041",
  appId: "1:419369971041:web:33532896ba4f2d7019a920",
  measurementId: "G-SNN8XWQENG"
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
