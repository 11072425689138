import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../firebase/firestore';
import { useUser } from "../context/userContext";
import { IoSettingsOutline, IoVolumeMute, IoVolumeMediumSharp } from "react-icons/io5";
import { FaArrowUp, FaArrowDown, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Animate from "../Components/Animate";
import { useNavigate } from 'react-router-dom';

const SnakeGame = () => {
  const navigate = useNavigate();
  const [backLos, setBackLos] = useState(true);
  
  // Game Constants
  const GRID_SIZE = 20; // 20x20 grid
   const CELL_SIZE = Math.min(window.innerWidth, window.innerHeight) / GRID_SIZE * 0.8; 
  const INITIAL_SNAKE = [
    { x: 10, y: 10 },
    { x: 9, y: 10 },
    { x: 8, y: 10 },
  ];
  const INITIAL_DIRECTION = { x: 1, y: 0 };
  const GAME_SPEED = 200; // milliseconds

  // User Context
  const { id, balance, setBalance, miningTotal, setMiningTotal } = useUser();

  // Game State
  const [snake, setSnake] = useState(INITIAL_SNAKE);
  const [direction, setDirection] = useState(INITIAL_DIRECTION);
  const [food, setFood] = useState(getRandomFood(INITIAL_SNAKE));
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  // Settings State with Local Storage
  const [vibrateOnEat, setVibrateOnEat] = useState(() => {
    const saved = localStorage.getItem('vibrateOnEat');
    return saved === null ? false : JSON.parse(saved);
  });
  const [isMuted, setIsMuted] = useState(() => {
    const saved = localStorage.getItem('isMuted');
    return saved === null ? false : JSON.parse(saved);
  });

  const gameLoopRef = useRef(null);
  const directionRef = useRef(direction);
  directionRef.current = direction;

  // Initialize the game loop
  useEffect(() => {
    if (!gameOver && !isPaused) {
      gameLoopRef.current = setInterval(moveSnake, GAME_SPEED);
    }
    return () => clearInterval(gameLoopRef.current);
    // eslint-disable-next-line
  }, [snake, gameOver, isPaused]);

  // Handle keyboard inputs
  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowUp':
          if (directionRef.current.y === 0) setDirection({ x: 0, y: -1 });
          break;
        case 'ArrowDown':
          if (directionRef.current.y === 0) setDirection({ x: 0, y: 1 });
          break;
        case 'ArrowLeft':
          if (directionRef.current.x === 0) setDirection({ x: -1, y: 0 });
          break;
        case 'ArrowRight':
          if (directionRef.current.x === 0) setDirection({ x: 1, y: 0 });
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Move the snake
  const moveSnake = () => {
    const newSnake = [...snake];
    const head = { ...newSnake[0] };
    head.x += directionRef.current.x;
    head.y += directionRef.current.y;

    // Check wall collision
    if (head.x < 0 || head.x >= GRID_SIZE || head.y < 0 || head.y >= GRID_SIZE) {
      setGameOver(true);
      return;
    }

    // Check self collision
    for (let segment of newSnake) {
      if (segment.x === head.x && segment.y === head.y) {
        setGameOver(true);
        return;
      }
    }

    newSnake.unshift(head);

    // Check food collision
    if (head.x === food.x && head.y === food.y) {
      setScore(prev => prev + 10); // 10 points per bite
      setFood(getRandomFood(newSnake));
      if (vibrateOnEat && "vibrate" in navigator) {
        navigator.vibrate(200); // Trigger vibration when eating food
      }
    } else {
      newSnake.pop();
    }

    setSnake(newSnake);
  };

  // Generate random food position
  function getRandomFood(snakeParts) {
    let newFood;
    while (true) {
      newFood = {
        x: Math.floor(Math.random() * GRID_SIZE),
        y: Math.floor(Math.random() * GRID_SIZE),
      };
      if (!snakeParts.some(segment => segment.x === newFood.x && segment.y === newFood.y)) {
        break;
      }
    }
    return newFood;
  }

  // Handle on-screen pad controls
  const handlePadDirection = (dir) => {
    switch (dir) {
      case 'UP':
        if (directionRef.current.y === 0) setDirection({ x: 0, y: -1 });
        break;
      case 'DOWN':
        if (directionRef.current.y === 0) setDirection({ x: 0, y: 1 });
        break;
      case 'LEFT':
        if (directionRef.current.x === 0) setDirection({ x: -1, y: 0 });
        break;
      case 'RIGHT':
        if (directionRef.current.x === 0) setDirection({ x: 1, y: 0 });
        break;
      default:
        break;
    }
  };

  // Claim points and update Firebase
  const claimPoints = async () => {
    if (id && score > 0) {
      try {
        const userRef = doc(db, 'telegramUsers', id.toString());
        const newBalance = balance + score;
        const newMiningTotal = miningTotal + score;
        await updateDoc(userRef, { 
          balance: newBalance,
          miningTotal: newMiningTotal,
        });
        setBalance(newBalance);
        setMiningTotal(newMiningTotal);
        setScore(0);
      } catch (error) {
        console.error("Error updating balance:", error);
      }
    }
  };

  // Restart the game
  const restartGame = () => {
    setSnake(INITIAL_SNAKE);
    setDirection(INITIAL_DIRECTION);
    setFood(getRandomFood(INITIAL_SNAKE));
    setScore(0);
    setGameOver(false);
    setIsPaused(false);
  };

  // Toggle settings modal
  const toggleSettings = () => setShowSettings(!showSettings);

  // Toggle mute sound and close settings
  const handleToggleMute = () => {
    const newMuted = !isMuted;
    setIsMuted(newMuted);
    localStorage.setItem('isMuted', JSON.stringify(newMuted));
    // Implement actual sound muting logic if applicable
    setShowSettings(false); // Close the settings modal
  };

  // Toggle vibration on eat and close settings
  const handleToggleVibration = () => {
    const newVibrate = !vibrateOnEat;
    setVibrateOnEat(newVibrate);
    localStorage.setItem('vibrateOnEat', JSON.stringify(newVibrate));
    setShowSettings(false); // Close the settings modal
  };

  // Handle back navigation for Telegram WebApp
  useEffect(() => {
    const handleBackButtonClick = () => {
      navigate('/game'); // Navigate to /game without refreshing the page
      setBackLos(false);
    };

    if (backLos) {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(handleBackButtonClick);
      }
    } else {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
      }
    }

    // Cleanup handler when component unmounts
    return () => {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
      }
    };
  }, [backLos, navigate]);

  return (
    <Animate>
      <div className="w-[350px] h-auto flex flex-col items-center justify-start px-4 py-8 mx-auto min-h-screen">
        {/* Top Section: Balance and Settings */}
        <div className="w-full flex items-center justify-between mb-4">
          {/* Balance Display */}
          <div className="bg-cards px-4 py-2 text-[15px] font-semibold rounded-full flex items-center space-x-1">
            <img src="/stars.svg" alt="Balance Icon" className="w-[14px]" />
            <span className="text-secondary">Balance:</span>
            <span>{balance}</span>
          </div>

          <div className="mt-2 text-white text-lg font-semibold">
          Score: {score}
        </div>

          {/* Settings Button */}
          <button onClick={toggleSettings} className="bg-transparent hover:bg-secondary p-2 rounded-full">
            <IoSettingsOutline size={24} className="text-white" />
          </button>
        </div>

        {/* Game Grid */}
        <div className="relative mb-4">
          <div 
            className="relative grid" 
            style={{
              gridTemplateRows: `repeat(${GRID_SIZE}, ${CELL_SIZE}px)`,
              gridTemplateColumns: `repeat(${GRID_SIZE}, ${CELL_SIZE}px)`,
              backgroundColor: '#111827',
              width: `${GRID_SIZE * CELL_SIZE}px`,
              height: `${GRID_SIZE * CELL_SIZE}px`,
              border: '2px solid #374151',
              borderRadius: '8px',
            }}
          >
            {/* Render Food */}
            <div 
              className="absolute bg-red-500 animate-pulse" 
              style={{
                top: `${food.y * CELL_SIZE}px`,
                left: `${food.x * CELL_SIZE}px`,
                width: `${CELL_SIZE}px`,
                height: `${CELL_SIZE}px`,
                borderRadius: '50%',
                boxShadow: '0 0 10px rgba(255, 0, 0, 0.7)',
              }}
            ></div>

            {/* Render Snake */}
            {snake.map((segment, idx) => (
              <div 
                key={idx} 
                className="absolute bg-gradient-to-b from-green-400 to-green-700" 
                style={{
                  top: `${segment.y * CELL_SIZE}px`,
                  left: `${segment.x * CELL_SIZE}px`,
                  width: `${CELL_SIZE}px`,
                  height: `${CELL_SIZE}px`,
                  borderRadius: '50%',
                  boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.6)',
                }}
              ></div>
            ))}
          </div>

          {/* Game Over Modal */}
          {gameOver && (
            <div className="absolute inset-0 bg-black bg-opacity-75 flex flex-col items-center justify-center rounded-lg">
              <h3 className="text-3xl text-red-500 mb-4">Game Over</h3>
              <p className="text-white text-xl mb-6">Your Score: {score}</p>
              <button 
                onClick={restartGame}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded mb-4"
              >
                Restart
              </button>
              <button 
                onClick={claimPoints}
                className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-6 rounded ${score === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={score === 0}
              >
                Claim {score} Points
              </button>
            </div>
          )}
        </div>

        {/* On-Screen Pad Controls */}
        <div className="flex flex-col items-center space-y-4 mb-4">
          {/* Up Button */}
          <button
            onClick={() => handlePadDirection('UP')}
            className="bg-gradient-to-tr from-blue-500 to-blue-700 text-white font-bold py-6 px-6 rounded-lg shadow-lg hover:scale-105 transform transition-transform duration-150"
          >
            <FaArrowUp size={20} />
          </button>

          {/* Left, Down, and Right on the Same Line */}
          <div className="flex space-x-4">
            <button
              onClick={() => handlePadDirection('LEFT')}
              className="bg-gradient-to-tr from-blue-500 to-blue-700 text-white font-bold py-6 px-6 rounded-lg shadow-lg hover:scale-105 transform transition-transform duration-150"
            >
              <FaArrowLeft size={20} />
            </button>
            <button
              onClick={() => handlePadDirection('DOWN')}
              className="bg-gradient-to-tr from-blue-500 to-blue-700 text-white font-bold py-6 px-6 rounded-lg shadow-lg hover:scale-105 transform transition-transform duration-150"
            >
              <FaArrowDown size={20} />
            </button>
            <button
              onClick={() => handlePadDirection('RIGHT')}
              className="bg-gradient-to-tr from-blue-500 to-blue-700 text-white font-bold py-6 px-6 rounded-lg shadow-lg hover:scale-105 transform transition-transform duration-150"
            >
              <FaArrowRight size={20} />
            </button>
          </div>
        </div>

        {/* Score Display */}
        

        {/* Settings Modal - Replaced with New Design */}
        {showSettings && (
          <div className="fixed left-0 right-0 z-20 top-[-12px] bottom-0 flex justify-center taskbg px-[16px] h-full">
            <div id="refer" className='w-full flex flex-col'>
              <div className="w-full flex pt-6 flex-col space-y-6 overflow-y-auto pb-[100px] scroller">
                <div className="flex items-center space-x-4">
                  <div className='w-full'>
                    <h1 className='font-semibold text-[24px] text-center pb-4'>
                      Settings
                    </h1>

                    <div className="w-full flex flex-col pb-[100px]">
                      <div className='flex w-full flex-col space-y-2'>
                        {/* Mute Sound Button */}
                        

                        {/* Vibration Toggle Button */}
                        <button 
                          onClick={handleToggleVibration}
                          className={`text-[15px] text-[#d2d2d2] bg-cards3 hover:bg-cards ease-in duration-200 h-[60px] rounded-[14px] px-4 flex justify-between items-center`}
                        >
                          <div className='flex items-center space-x-2 justify-start w-[80%]'>
                            <span>
                              <IoSettingsOutline size={18} />
                            </span>
                            <div className='flex flex-col text-left'>
                              <h2 className='flex flex-1 font-medium text-[13px]'>
                                {vibrateOnEat ? 'Disable vibration on eat' : 'Enable vibration on eat'}
                              </h2>
                            </div>
                          </div>
                          {/* Optionally, add an icon here if needed */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Close Settings Modal */}
              <div className="absolute bottom-8 w-full flex justify-center">
                <button 
                  onClick={toggleSettings}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Animate>
  );
};

export default SnakeGame;
