import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, FaStar, FaTimes } from 'react-icons/fa';
import Animate from '../Components/Animate.js';
import GamePlay from '../Components/GamePlay';

// Sample game data
const gamesData = [
  {
    id: 1,
    title: 'Snake Game',
    description: 'Experience the classic Snake game with modern twists!',
    rewards: 'Earn up to 100 points',
    rating: 4.5,
    image: '/snakeGameImage.webp', // Ensure this image exists in public/images/
    path: '/snakegame',
  },
  {
    id: 2,
    title: 'Crypto Farm',
    description: 'Mine crypto and manage your virtual farm efficiently.',
    rewards: 'Earn crypto rewards',
    rating: 4.0,
    image: '/mining.jpg',
    path: '/mining',
  },
];

const Game = () => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIndex, setActiveIndex] = useState(1);

  // Open Game Modal
  const openGameModal = (game) => {
    setSelectedGame(game);
  };

  // Close Game Modal
  const closeGameModal = () => {
    setSelectedGame(null);
  };

  // Handle Play Game
  const handlePlayGame = () => {
    if (selectedGame) {
      window.location.href = selectedGame.path;
    }
  };

  const handleMenu = (index) => {
    setActiveIndex(index);
  };

  // Filter games based on search term
  const filteredGames = gamesData.filter((game) =>
    game.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    game.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Animate>
      <div className="min-h-screen flex flex-col bg-black">

        {/* Games Section */}

        <div className="flex flex-col items-center px-4">
        <div className={`${activeIndex === 1 ? 'flex' : 'hidden'} w-full flex-col spacey-1 pt-6 relative !mt-0`}>
       <GamePlay/>
      </div>
        <div className="flex flex-wrap gap-4 w-full">
            {filteredGames.map((game) => (
            <div
                key={game.id}
                className="bg-[#202124] shadow-lg rounded-lg p-4 cursor-pointer flex-1"
                onClick={() => openGameModal(game)}
            >
                <img
                src={game.image}
                alt={game.title}
                className="w-full h-32 object-cover rounded-md mb-4"
                />
                <div className="text-center">
                <h3 className="text-md font-medium">{game.title}</h3>
                <p className="text-[12px]">{game.rewards}</p>
                
                </div>
            </div>
            ))}
        </div>
        {filteredGames.length === 0 && (
            <div className="mt-6 text-sm font-medium text-gray-400">
            No games found.
            </div>
        )}
        </div>

        <div className="mt-8 text-center text-sm text-white">
            More games coming soon...
          </div>

        {/* Game Modal */}
        {selectedGame && (
          <div
            className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 backdrop-blur-lg"
            onClick={closeGameModal}
          >
            <div
              className="bg-black p-6 rounded-lg shadow-lg max-w-sm w-80 relative"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={closeGameModal}
                className="absolute top-3 right-3 text-gray-400 hover:text-white text-xl"
              >
                <FaTimes />
              </button>
              <div className="text-center">
                <img
                  src={selectedGame.image}
                  alt={selectedGame.title}
                  className="w-28 h-28 mx-auto mb-4 rounded-full shadow-md"
                />
                <h3 className="text-md font-bold text-white mb-2">{selectedGame.title}</h3>
                <p className="text-gray-400 text-sm mb-4">{selectedGame.description}</p>
                <div className="flex justify-center items-center mb-4">
                  {Array.from({ length: 5 }, (_, index) => (
                    <FaStar
                      key={index}
                      className={`mr-1 ${index < Math.floor(selectedGame.rating) ? 'text-yellow-400' : 'text-gray-500'}`}
                    />
                  ))}
                  <span className="text-sm text-gray-400 ml-1">{selectedGame.rating}</span>
                </div>
                <Link
                  to={selectedGame.path}
                  className="inline-block bg-[#3B82F6] hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full transition-colors duration-200"
                >
                  Play Now
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </Animate>
  );
};

export default Game;
