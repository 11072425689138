// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Ref from "./pages/Ref.js";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home.js";
import ErrorCom from "./Components/ErrorCom.js";
import NotAdmin236 from "./pages/NotAdmin236.js";
import { AuthContextProvider } from "./context/AuthContext.js";
import Leaderboard from "./pages/Leaderboard.js";
import DailyCheckIn from "./pages/Checkin.js";
import CryptoFarming from "./Components/Farm.js";
import Airdrop from "./pages/Airdrop.js";
import Dashboard from "./pages/admin/Dashboard.js";
import Settings from "./pages/admin/Settings.js";
import EditTasks from "./pages/admin/EditTasks.js";
import ExtrenalTasks from "./pages/admin/ExtrenalTasks.js";
import AdminAdvertTasks from "./pages/admin/AdminAdvertTasks.js";
import AirdropWallets from "./pages/admin/AdminWallets.js";
import Search from "./pages/admin/Search.js";
import Statistics from "./pages/admin/Statistics.js";
import AdminRanks from "./pages/admin/AdminRanks.js";
import AdminYoutube from "./pages/admin/AdminYoutube.js";
import AlphaDogs from "./pages/AlphaDogs.js";
import Game from "./pages/Game.js";
import SnakeGame from "./Components/SnakeGame.js";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorCom />,
    children:[
      {
        path:"/",
        element: <AlphaDogs />,
      },
      {
        path:"/ref",
        element: <Ref />,
      },
      {
        path:"/airdrop",
        element: <Airdrop />,
      },
      {
        path:"/leaderboard",
        element: <Leaderboard />,
      },
      {
        path:"/checkin",
        element: <DailyCheckIn />,
      },
      {
        path:"/mining",
        element: <CryptoFarming/>,
      },
      {
        path:"/game",
        element: <Game/>,
      },
      {
        path:"/snakegame",
        element: <SnakeGame/>,
      },
      {
        path:"/dashboardlogin",
        element: <NotAdmin236 />,
      },
    ]
  },
  {
    path: "/dashboardAdx",
    element: <Dashboard />,
    errorElement: <ErrorCom />,
    children:[
      {
        path:"/dashboardAdx/settings",
        element: <Settings />,
      },
      {
        path:"/dashboardAdx/managetasks",
        element: <EditTasks />,
      },
      {
        path:"/dashboardAdx/externaltasks",
        element: <ExtrenalTasks />,
      },
      {
        path:"/dashboardAdx/promo",
        element: <AdminAdvertTasks />,
      },
      {
        path:"/dashboardAdx/youtube",
        element: <AdminYoutube />,
      },
      {
        path:"/dashboardAdx/airdroplist",
        element: <AirdropWallets />,
      },
      {
        path:"/dashboardAdx/ranks",
        element: <AdminRanks />,
      },
      {
        path:"/dashboardAdx/search",
        element: <Search />,
      },
      {
        path:"/dashboardAdx/stats",
        element: <Statistics />,
      },
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </AuthContextProvider>
);
