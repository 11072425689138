import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "../App.css";
import "../fire.scss";
import { AnimatePresence } from "framer-motion";
import Footer from "../Components/Footer";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { UserProvider } from "../context/userContext";

const tele = window.Telegram.WebApp;

const Home = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [hasVisitedBefore, setHasVisitedBefore] = useState(false);
  const [hider, setHider] = useState(false);
  const [restrictAccess, setRestrictAccess] = useState(false);
  const scrollableEl = useRef(null);

  // Prevent context menu and specific key shortcuts
  useEffect(() => {
    const handleContextMenu = (event) => event.preventDefault();
    const handleKeyDown = (event) => {
      if (
        (event.ctrlKey && (event.key === "u" || event.key === "s")) ||
        (event.ctrlKey && event.shiftKey && event.key === "i")
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Initialize Telegram Web App
  useEffect(() => {
    if (tele) {
      tele.ready();
      tele.expand();
      setTimeout(() => {
        setLoading(false);
      }, 2000);

      // Set header color
      tele.setHeaderColor("#000");

      // Haptic feedback
      if (tele.HapticFeedback) {
        tele.HapticFeedback.impactOccurred("medium");
      }
      if (navigator.vibrate) {
        navigator.vibrate(100); // Vibrate for 100ms
      }
    }
  }, []);

  // Check if the user has visited before using localStorage
  useEffect(() => {
    const visited = localStorage.getItem("hasVisitedBefore");
    if (visited) {
      setHasVisitedBefore(true);
    } else {
      setHider(true);
      localStorage.setItem("hasVisitedBefore", "true");
    }

    setTimeout(() => {
      setHider(false);
    }, 1000);
  }, []);

  useEffect(() => {
    // Define admin routes that should always be accessible
    const adminRoutes = [
      "/dashboardAdx",
      "/dashboardlogin",
      "/dashboardAdx/settings",
      "/dashboardAdx/managetasks",
      "/dashboardAdx/externaltasks",
      "/dashboardAdx/promo",
      "/dashboardAdx/youtube",
      "/dashboardAdx/airdroplist",
      "/dashboardAdx/ranks",
      "/dashboardAdx/search",
      "/dashboardAdx/stats"
    ];
  
    // Check if the current location is an admin route
    const isAdminRoute = adminRoutes.some(route => location.pathname.startsWith(route));
  
    if (isAdminRoute) {
      // If it's an admin route, allow access and skip further checks
      setRestrictAccess(false);
      return;
    }
  
    // Function to check if a valid Telegram user exists
    const checkTelegramUser = () => {
      if (window.Telegram && window.Telegram.WebApp) {
        const telegram = window.Telegram.WebApp;
        const user = telegram.initDataUnsafe && telegram.initDataUnsafe.user;
  
        // Check for user with id or username
        if (user && (user.id || user.username)) {
          console.log("User found with ID or Username:", user.id || user.username);
          return true; // Allow access if user exists
        }
  
        console.log("User not found");
        return false; // Block access if no user
      }
  
      console.log("Not a Telegram WebApp, blocking access");
      return false; // Block if Telegram WebApp is unavailable
    };
  
    // Check user access for non-admin routes
    if (!checkTelegramUser()) {
      setRestrictAccess(true); // Restrict access if no valid user
    } else {
      setRestrictAccess(false); // Allow access if valid user exists
    }
  }, [location]);
  



  return (
    <>
      <div className="w-full flex justify-center">
        <div className="w-full flex justify-center">
          <div className="flex flex-col space-y-3 w-full">
            <TonConnectUIProvider manifestUrl="https://app.jagscoin.com/tonconnect-manifest.json">
              <UserProvider>
                <AnimatePresence mode="wait">
                  {restrictAccess ? (
                    <div className="w-full flex h-full justify-center px-5 items-center font-medium text-[20px]">
                      <div className="w-full pt-24 text-center flex flex-col space-y-3 justify-center items-center">
                        <p className="text-[18px]">
                          "Mobile is perfect for gaming! 🎮 Open on your Telegram app and start playing now!"
                        </p>
                        <img src="/stars.svg" alt="stars" className="w-[150px] mt-40" />

                    <button
  onClick={() => window.location.href = "https://t.me/jagscoin_bot?start"}
  className="w-[65%] flex space-x-2 mt-28 font-medium text-[14px] barTitle bg-btn h-[45px] rounded-[10px] px-4 justify-center items-center text-center"
>
  <span>Open JAGS Bot</span>
</button>

                      </div>
                    </div>
                  ) : (
                    <>
                      <Outlet />
                      <div
                        id="footermain"
                        className={`${loading || hider ? "hidden" : "flex"} z-30 flex-col bg-[#000] fixed bottom-0 pt-3 pb-6 left-0 right-0 justify-center items-center px-3`}
                      >
                        <Footer />
                        <div className="bg-[#000] z-20 h-[67px] w-full fixed bottom-0 left-0 right-0 "></div>
                      </div>
                    </>
                  )}
                </AnimatePresence>
              </UserProvider>
            </TonConnectUIProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
